.card-card {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  padding: 20px;
  max-width: 650px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/remon-82925.appspot.com/o/df4c8e4cdb02ab3b4171912f7018266b.jpg?alt=media&token=f14e4714-ec22-468a-88e4-8c4d9321bb1a");
}
.card-info {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.card-text1 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.card-text2 {
  line-height: 1.5;
}

.cardroot-class-name1 {
  background-size: cover;
  background-image: url("/rectangle%2035%20%5B2%5D-200h.png");
}
.cardroot-class-name2 {
  background-size: cover;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/remon-82925.appspot.com/o/photo1668707406_1080x.webp?alt=media&token=fd894900-f39d-496d-b104-3e972576591a");
}
@media(max-width: 767px) {
  .card-card {
    width: 100%;
  }
}
