@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bad+Script&family=Borel&family=Fjalla+One&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,700&family=Mooli&family=Passion+One:wght@900&family=Russo+One&family=Young+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bad+Script&family=Borel&family=Fjalla+One&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,700&family=Mooli&family=Passion+One:wght@900&family=Russo+One&family=Young+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
.css-7vq5hu{font:var(--podium-cds-typography-960-to-1919-display2);text-transform:uppercase;font-weight:500;display:undefined;font-size: 30px;}
.items{
  font-family: 'Courier New';
  font-size: 12px;
}
.successCourseProgram{
  background-color: #00ff59;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header{
  font-family: "Sansita", serif;
  font-feature-settings: normal;
  font-size: 4.5rem;
  letter-spacing: -.01em;
  line-height: 90%;
  font-weight: 400;
}
.input-field-signUp:-webkit-autofill {
  caret-color: black;
  -webkit-box-shadow: 0 0 0 70px white inset; /* Override the autofill background color */
}
.bigScreenFlex{
display:flex;
flex: 1 1 0%;

}

.bg-neutral-10 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 250 / var(--tw-bg-opacity));
}
.text-blue-60 {
  --tw-text-opacity: 1;
  color: rgb(3 60 173 / var(--tw-text-opacity));
}
.employers-form_employersMktoForm__F8_E7 button {
  margin-top: 3.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(211 251 82 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.employers-form_employersMktoForm__F8_E7 .mktoLabel {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
.mktoForm .mktoButtonWrap.mktoGlow .mktoButton {
  color:#fff;
  background:#fff;
  border:1px solid #F9B872;
  padding:0.4em 1em;
  font-size:1em;
  box-shadow: 0px 0px 10px 2px #FDEEA6;
  background-color:#F49F6C;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F49F6C), to(#EC802F));
  background-image: -webkit-linear-gradient(top, #F49F6C, #EC802F);
  background-image: -moz-linear-gradient(top, #F49F6C, #EC802F);
  background-image: linear-gradient(to bottom, #F49F6C, #EC802F);
  }
  .mktoForm .mktoButtonWrap.mktoGlow .mktoButton:hover {
  border:1px solid #db6700;
  }
  .mktoForm .mktoButtonWrap.mktoGlow .mktoButton:focus {
  outline:none;
  border:1px solid #db6700;
  }
  .mktoForm .mktoButtonWrap.mktoGlow .mktoButton:active{
  box-shadow: 0px 0px 10px 2px #afd5f7;
  background-color:#EC802F;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#EC802F), to(#F49F6C));
  background-image: -webkit-linear-gradient(top, #EC802F, #F49F6C);
  background-image: -moz-linear-gradient(top, #EC802F, #F49F6C);
  background-image: linear-gradient(to bottom, #EC802F, #F49F6C);
  }
.input-field-signUp::placeholder {
  background-color: transparent; /* Set the background color of the placeholder text to transparent */
}
.fEUgVc {
  min-height: calc(-121px + 100vh);
  max-width: 1520px;
}
.bg-neutral-90 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 21 28 / var(--tw-bg-opacity));
}
.text-lime-30 {
  --tw-text-opacity: 1;
  color: rgb(211 251 82 / var(--tw-text-opacity));
}
.border-btn{
  margin-top: 10px;
  border: 1px solid black;
}
.gMSCii {
  display: flex
;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 60px;
  font-size: 14px;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1.5;
}
#amountModal{
  width: 100%;
        min-height: 100vh;

  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
transform: translate(-50%,-50%);
background-color: rgba(0, 0, 0,0.75);
display: flex;
justify-content: center;
align-items: center;
}

.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.fabricDetails{
  margin-top: 70px;
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
/* In your tailwind.css or a custom CSS file */
.mask-radial-gradient {
  mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
}
.border-b-2{
  border-bottom:3px solid black;
}
.mainNav{
  z-index:1001;
}

.link {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.link:hover {
  color: rgba(0, 0, 0, 0.6);
}
.accordion-element {
  gap: var(--dl-space-space-fiveunits);
  cursor: pointer;
  display: flex;
  position: relative;
  transition: .5s all linear;;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #DBDBDB;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.accordion-element:hover {
  fill: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}
.Question {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 640px;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.active-quote {
  display: flex !important;
  align-items: center;
  flex-direction: column;
}
.quote {
  display: none;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

@media(max-width: 767px) {
  .Question {
    gap: var(--dl-space-space-halfunit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
}
.adminList{
  padding-top: 5px;
  border-top: 1px solid black;
}
.raleway {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
 
  font-style: normal;
}
.page-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.page-form {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-types {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.page-text10 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.page-text11 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.page-inputs {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-date {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
}
.page-image {
  width: 20px;
  object-fit: cover;
  border-color: #B4B9C6;
  padding-bottom: var(--dl-space-space-unit);
  border-bottom-width: 1px;
}
.page-lower {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.page-text12{
  font-size: 14px;
  text-align: left;
  color: blue;
}
.page-button {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.page-book {
  width: 100%;
}
.page-text14 {
  font-size: 10px;
  max-width: 700px;
  text-align: left;
  line-height: 15px;
}
.page-text16 {
  color: #2461ff;
  font-style: normal;
  font-weight: 600;
}
.page-text18 {
  color: #2461ff;
  font-style: normal;
  font-weight: 600;
}
@media(max-width: 767px) {
  .page-form {
    gap: var(--dl-space-space-threeunits);
  }
  .page-types {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .page-inputs {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .page-types {
    flex-direction: column;
  }
  
}
:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}

.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  outline: none;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.button-main {
  gap: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: black;
  font-size: 13px;
}
.button-main:hover {
  opacity: 0.75;
}
.quick-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  border-right-width: 1px;
}
.quick-links:hover {
  opacity: 0.5;
}
.read-more {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
}
.read-more:hover {
  opacity: 0.5;
}
.button-white {
  color: #000000;
  background-color: #ffffff;
}
.symptom {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #E9E9EB;
}
.symptom:hover {
  opacity: 0.5;
}
.letter {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 60px;
  transition: 0.3s;
  align-items: center;
  aspect-ratio: 1;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-round);
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #E9E9EB;
}
.letter:hover {
  opacity: 0.5;
}
.book-type {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
.book-type:hover {
  color: white;
  background-color: black;
}
.book-input {
  width: 100%;
  padding-top: 0px;
  font-size: 13px;
  border-color: #B4B9C6;
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom:10px;
  background-color: transparent;
  border-bottom-width: 1px;
}
.arrow {
  width: 60px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.arrow:hover {
  opacity: 0.5;
}
.legal-link {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 12px;
  transition: 0.3s;
}
.legal-link:hover {
  opacity: 0.5;
}
.social {
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(236, 233, 234, 0.2);
}
.social:hover {
  opacity: 0.5;
}
.letter-active {
  color: white;
  background-color: #2461FF;
}
.search-result {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #E9E9EB;
}
.result-text {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.more-results {
  color: white;
  background-color: #2461FF;
}
.invisible {
  opacity: 0;
  animation: fade-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transform-origin: 0 50%;
}
.Content {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.deleteModal{
  width: 40%;
}
@media(max-width: 991px) {
  .letter {
    max-width: calc(100% / 6 - 20px);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .arrow {
    width: 40px;
  }
  .deleteModal{
    width: 100%;
  }
  .mobLogin{
    width: 90%;
    margin: auto;
  }
}

p9e-upsell-pillar .p9e-pillar-row {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 300px 300px;
  margin: 8px 0;
}
 .feature-card-image[_ngcontent-ng-c1992778410] {
  border-bottom: 1px solid var(--theme-color-fg-default-border);
  width: 100%;
  height: auto;
}
.mat-card.mat-card{
  background-color: white;
  display: block;
  min-height: 220px;
  overflow: hidden;
  padding: 0;
}

[_nghost-ng-c1992778410] {
  box-sizing: border-box;
  display: inline-block;
}
p9e-upsell-card fire-feature-card {
  height: 100%;
}
p9e-page {
  --fire-color-featurebar-bg: rgb(26, 115, 232);
  display: flex
;
  flex-direction: column;
  flex-grow: 1;
  min-width: 340px;
}
 .feature-card-header[_ngcontent-ng-c1992778410] {
  padding: 12px 24px;
}
 .feature-card-title[_ngcontent-ng-c1992778410] {
  color:white;
  font-family: "Google Sans", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  font-family: "Google Sans", sans-serif;
}
 .feature-card-subtitle[_ngcontent-ng-c1992778410] {
 color: white;
  font-size: 13px;
  line-height: 20px;
}


fire-feature-card {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
    background-color: #ff0061;
    border-radius: 10px;
}
/* From Uiverse.io by Nawsome */ 
.typewriter {
  --blue: #5C86FF;
  --blue-dark: #275EFE;
  --key: #fff;
  --paper: #EEF0FD;
  --text: #D3D4EC;
  --tool: #FBC56C;
  --duration: 3s;
  position: relative;
  -webkit-animation: bounce05 var(--duration) linear infinite;
  animation: bounce05 var(--duration) linear infinite;
}

.typewriter .slide {
  width: 92px;
  height: 20px;
  border-radius: 3px;
  margin-left: 14px;
  transform: translateX(14px);
  background: linear-gradient(var(--blue), var(--blue-dark));
  -webkit-animation: slide05 var(--duration) ease infinite;
  animation: slide05 var(--duration) ease infinite;
}

.typewriter .slide:before, .typewriter .slide:after,
.typewriter .slide i:before {
  content: "";
  position: absolute;
  background: var(--tool);
}

.typewriter .slide:before {
  width: 2px;
  height: 8px;
  top: 6px;
  left: 100%;
}

.typewriter .slide:after {
  left: 94px;
  top: 3px;
  height: 14px;
  width: 6px;
  border-radius: 3px;
}

.typewriter .slide i {
  display: block;
  position: absolute;
  right: 100%;
  width: 6px;
  height: 4px;
  top: 4px;
  background: var(--tool);
}

.typewriter .slide i:before {
  right: 100%;
  top: -2px;
  width: 4px;
  border-radius: 2px;
  height: 14px;
}

.typewriter .paper {
  position: absolute;
  left: 24px;
  top: -26px;
  width: 40px;
  height: 46px;
  border-radius: 5px;
  background: var(--paper);
  transform: translateY(46px);
  -webkit-animation: paper05 var(--duration) linear infinite;
  animation: paper05 var(--duration) linear infinite;
}

.typewriter .paper:before {
  content: "";
  position: absolute;
  left: 6px;
  right: 6px;
  top: 7px;
  border-radius: 2px;
  height: 4px;
  transform: scaleY(0.8);
  background: var(--text);
  box-shadow: 0 12px 0 var(--text), 0 24px 0 var(--text), 0 36px 0 var(--text);
}

.typewriter .keyboard {
  width: 120px;
  height: 56px;
  margin-top: -10px;
  z-index: 1;
  position: relative;
}

.typewriter .keyboard:before, .typewriter .keyboard:after {
  content: "";
  position: absolute;
}

.typewriter .keyboard:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  background: linear-gradient(135deg, var(--blue), var(--blue-dark));
  transform: perspective(10px) rotateX(2deg);
  transform-origin: 50% 100%;
}

.typewriter .keyboard:after {
  left: 2px;
  top: 25px;
  width: 11px;
  height: 4px;
  border-radius: 2px;
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  -webkit-animation: keyboard05 var(--duration) linear infinite;
  animation: keyboard05 var(--duration) linear infinite;
}

@keyframes bounce05 {
  85%, 92%, 100% {
    transform: translateY(0);
  }

  89% {
    transform: translateY(-4px);
  }

  95% {
    transform: translateY(2px);
  }
}

@keyframes slide05 {
  5% {
    transform: translateX(14px);
  }

  15%, 30% {
    transform: translateX(6px);
  }

  40%, 55% {
    transform: translateX(0);
  }

  65%, 70% {
    transform: translateX(-4px);
  }

  80%, 89% {
    transform: translateX(-12px);
  }

  100% {
    transform: translateX(14px);
  }
}

@keyframes paper05 {
  5% {
    transform: translateY(46px);
  }

  20%, 30% {
    transform: translateY(34px);
  }

  40%, 55% {
    transform: translateY(22px);
  }

  65%, 70% {
    transform: translateY(10px);
  }

  80%, 85% {
    transform: translateY(0);
  }

  92%, 100% {
    transform: translateY(46px);
  }
}

@keyframes keyboard05 {
  5%, 12%, 21%, 30%, 39%, 48%, 57%, 66%, 75%, 84% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  9% {
    box-shadow: 15px 2px 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  18% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 2px 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  27% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 12px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  36% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 12px 0 var(--key), 60px 12px 0 var(--key), 68px 12px 0 var(--key), 83px 10px 0 var(--key);
  }

  45% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 2px 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  54% {
    box-shadow: 15px 0 0 var(--key), 30px 2px 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  63% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 12px 0 var(--key);
  }

  72% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 2px 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  81% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key), 60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key), 22px 10px 0 var(--key), 37px 12px 0 var(--key), 52px 10px 0 var(--key), 60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }
}
.homeLogo{
  width:140px
}
.smallDevices{
  display:none;
}
@media(max-width: 1000px) {
  .hideMobile{
   display:none;
  }
  .homeLogo{
    width:190px
  }
  .smallDevices{
    display:flex;
  }
}
.tablePadding{
  padding:25px 20px 25px 20px;

}
@media(max-width: 479px) {
  .homeLogo{
    width:140px;
  }

  .tablePadding{
    padding:15px 10px 15px 10px;

  }
  p9e-upsell-pillar .p9e-pillar-row {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 320px ;
    margin: 70px 0;
  }
}  #successAddFabric{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;

}.file-upload-container {
  position: relative;
  display: flex;
  align-items: center;
}

.file-input {
  opacity: 0; /* Hide the default file input */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; /* Make sure it's clickable */
}

.custom-text {
  background-color: transparent;
  border:2px solid black;

  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
}



.accessoryList{
  display: grid;
  grid-template-columns: auto  auto auto;
  column-gap: 5px;
  row-gap: 10px;
  justify-content: center;
  text-align: center; 
  word-break: keep-all;
  align-items: center;

}

@media(max-width:760px){
  .bigScreenFlex{
    display:flex;
    flex-direction: column;
    }
}