.about-container1 {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .about-navbar {
    align-items: center;
    flex-direction: column;
  }
  .about-navbar-interactive {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .about-logo1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-image1 {
    width: 22px;
    object-fit: cover;
    margin-right: 14px;
  }
  .about-text101 {
    color: var(--dl-color-scheme-orange100);
  }
  .about-desktop-menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .about-links1 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .about-text103 {
    color: var(--dl-color-scheme-green80);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-text104 {
    color: var(--dl-color-scheme-green80);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-text105 {
    color: var(--dl-color-scheme-green80);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-text106 {
    color: var(--dl-color-scheme-green80);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-burger-menu {
    display: none;
  }
  .about-icon10 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .about-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 32px;
    z-index: 100;
    position: absolute;
    transform: translateX(100%);
    transition: 0.5s;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
  .about-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .about-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .about-logo2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-image2 {
    width: 22px;
    object-fit: cover;
    margin-right: 14px;
  }
  .about-text108 {
    color: var(--dl-color-scheme-orange100);
  }
  .about-close-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-icon12 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .about-links2 {
    flex: 0 0 auto;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .about-text110 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text111 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text112 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text113 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text114 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-buttons {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-icon14 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-icon16 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-icon18 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .about-text115 {
    display: inline-block;
  }
  .about-text116 {
    display: inline-block;
  }
  .about-text117 {
    display: inline-block;
  }
  .about-text118 {
    display: inline-block;
  }
  .about-text119 {
    display: inline-block;
  }
  .about-text120 {
    display: inline-block;
  }
  .about-text121 {
    display: inline-block;
  }
  .about-text122 {
    display: inline-block;
  }
  .about-text123 {
    display: inline-block;
  }
  .about-text124 {
    display: inline-block;
  }
  .about-text125 {
    display: inline-block;
  }
  .about-text126 {
    display: inline-block;
  }
  .about-text127 {
    display: inline-block;
  }
  .about-text128 {
    display: inline-block;
  }
  .about-text129 {
    display: inline-block;
  }
  .about-text130 {
    display: inline-block;
  }
  .about-text131 {
    display: inline-block;
  }
  .about-text132 {
    display: inline-block;
  }
  .about-text133 {
    display: inline-block;
  }
  .about-text134 {
    display: inline-block;
  }
  .about-text135 {
    display: inline-block;
  }
  .about-text136 {
    display: inline-block;
  }
  .about-text137 {
    display: inline-block;
  }
  .about-text138 {
    display: inline-block;
  }
  .about-text139 {
    display: inline-block;
  }
  .about-text140 {
    display: inline-block;
  }
  .about-text141 {
    display: inline-block;
  }
  .about-text142 {
    display: inline-block;
  }
  .about-text143 {
    display: inline-block;
  }
  .about-text144 {
    display: inline-block;
  }
  .about-text145 {
    display: inline-block;
  }
  .about-text146 {
    display: inline-block;
  }
  .about-text147 {
    display: inline-block;
  }
  .about-text148 {
    display: inline-block;
  }
  .about-text149 {
    display: inline-block;
  }
  .about-text152 {
    display: inline-block;
  }
  .about-text153 {
    display: inline-block;
  }
  .about-text154 {
    display: inline-block;
  }
  .about-text155 {
    display: inline-block;
  }
  .about-text156 {
    display: inline-block;
  }
  .about-text157 {
    display: inline-block;
  }
  .about-text158 {
    display: inline-block;
  }
  .about-text159 {
    display: inline-block;
  }
  .about-text160 {
    display: inline-block;
  }
  .about-text161 {
    display: inline-block;
  }
  .about-text162 {
    display: inline-block;
  }
  .about-text163 {
    display: inline-block;
  }
  .about-text166 {
    display: inline-block;
  }
  .about-text167 {
    display: inline-block;
  }
  .about-text168 {
    display: inline-block;
  }
  .about-text169 {
    display: inline-block;
  }
  .about-text170 {
    display: inline-block;
  }
  .about-text171 {
    display: inline-block;
  }
  .about-text172 {
    display: inline-block;
  }
  .about-text173 {
    display: inline-block;
  }
  .about-text174 {
    display: inline-block;
  }
  .about-text175 {
    display: inline-block;
  }
  .about-footer {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .about-links-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-text176 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-text177 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text178 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text179 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text180 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text182 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-text183 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text184 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text185 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text186 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text188 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-text189 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text190 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text191 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text192 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text193 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text195 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-text196 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text197 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text198 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text201 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text204 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-text205 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text206 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text207 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text209 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-text210 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text211 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text212 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text213 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text214 {
    margin-bottom: var(--dl-space-space-unit);
  }
  @media(max-width: 991px) {
    .about-footer {
      flex-direction: column;
    }
    .about-links-container {
      width: auto;
      margin-top: var(--dl-space-space-twounits);
      flex-direction: row;
    }
    .about-container2 {
      flex-wrap: wrap;
      margin-right: 0px;
    }
  }
  @media(max-width: 767px) {
    .about-desktop-menu {
      display: none;
    }
    .about-links1 {
      display: none;
    }
    .about-burger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .about-text110 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .about-text111 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .about-text112 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .about-text113 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .about-text114 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .about-footer {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .about-links-container {
      width: auto;
      margin-top: var(--dl-space-space-twounits);
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .about-container2 {
      width: 100%;
      justify-content: space-between;
    }
  }
  @media(max-width: 479px) {
    .about-mobile-menu {
      padding: 16px;
    }
    .about-footer {
      padding: var(--dl-space-space-unit);
    }
    .about-links-container {
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
    }
    .about-container2 {
      align-items: center;
      margin-right: 0px;
      justify-content: space-between;
    }
  }
  